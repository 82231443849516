import React from "react"
import PropTypes from "prop-types"
import Loadable from "react-loadable"
import DefaultLayout from "../components/default-page-layout"

const Loading = Loadable({
  loader: () => import("../components/loadableMap"),
  loading() {
    return <div>Loading</div>
  },
})

export default function Map() {
  return (
    <>
      <DefaultLayout>
        <Loading />
      </DefaultLayout>
    </>
  )
}
